import { InjectionToken } from '@angular/core';

export type Theme = 'dark' | 'light';

export type VmrThemeManagerConfig = {
  /** Track user system preference changes. Defaults to `true`. */
  track?: boolean;
  /** The default theme. Defaults to `light`. */
  themeDefault?: Theme;
  /** Get initial value from localStorage (and save updates). Defaults to `true`. */
  useStorage?: boolean;
};

export const THEME_DEFAULT: Theme = 'light';
export const VMR_DARK_THEME_CLASS = 'vmr-dark-mode';
export const VMR_LIGHT_THEME_CLASS = 'vmr-light-mode';
export const VMR_THEME_LOCAL_STORAGE_KEY = 'vmr-theme-preference';
export const PREFERS_COLOR_SCHEME_DARK = '(prefers-color-scheme: dark)';

export const defaultConfig: VmrThemeManagerConfig = {
  track: false,
  useStorage: true,
  themeDefault: THEME_DEFAULT
};

export const VMR_THEME_MANAGER_CONFIG = new InjectionToken<VmrThemeManagerConfig>(
  'VMR_THEME_MANAGER_CONFIG',
  {
    providedIn: 'root',
    factory: () => defaultConfig,
  }
);

export function themeManagerConfig(
  config: Partial<VmrThemeManagerConfig>
): VmrThemeManagerConfig {
  return {
    ...defaultConfig,
    ...config,
  };
}