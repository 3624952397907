import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {hasMatchMediaSupport, LOCAL_STORAGE} from '@vermeer-corp/it-ng-components/core';
import {Injectable, PLATFORM_ID, afterNextRender, computed, inject, signal} from '@angular/core';
import {
  type Theme,
  VMR_DARK_THEME_CLASS,
  VMR_LIGHT_THEME_CLASS,
  PREFERS_COLOR_SCHEME_DARK,
  VMR_THEME_LOCAL_STORAGE_KEY,
  VMR_THEME_MANAGER_CONFIG,
  THEME_DEFAULT
} from './theme-manager-config';

@Injectable({
  providedIn: 'root'
})
export class VmrThemeManager {
  private readonly _document = inject(DOCUMENT);
  private readonly _platformId = inject(PLATFORM_ID);
  private readonly _localStorage = inject(LOCAL_STORAGE);
  private readonly _config = inject(VMR_THEME_MANAGER_CONFIG);
  private readonly _theme = signal<Theme | undefined>(this._config.themeDefault ?? THEME_DEFAULT);

  theme = this._theme.asReadonly();
  isDarkMode = computed<boolean>(() => this._theme() === 'dark');

  constructor() {
    if (isPlatformBrowser(this._platformId) && hasMatchMediaSupport()) {
      if (this._config.useStorage) {
        this._loadThemePreference();
      }

      if (this._config.track) {
        afterNextRender(() => this._initThemeWatcher());
      }
    }
  }

  setTheme(theme: Theme): void {
    this._theme.set(theme);
    this._toggleThemeClasses(theme);
    this._setLocalStorageTheme(theme);
  }

  /**
   * 1. Read theme preferences stored in localStorage.
   * 2. In case when there are no stored user preferences, then read them from device preferences.
   */
  private _loadThemePreference(): void {
    const themePref =
      this._getLocalStorageTheme() ??
      (window.matchMedia(PREFERS_COLOR_SCHEME_DARK).matches ? 'dark' : 'light') ??
      this._config.themeDefault;

    this._theme.set(themePref);
    this._toggleThemeClasses(themePref);
  }

  // Set theme classes on the body element
  private _toggleThemeClasses(theme: Theme): void {
    const docClassList = this._document.documentElement.classList;
    const classToAdd = theme === 'dark' ? VMR_DARK_THEME_CLASS : VMR_LIGHT_THEME_CLASS;
    const classToRemove = theme === 'dark' ? VMR_LIGHT_THEME_CLASS : VMR_DARK_THEME_CLASS;
    docClassList.add(classToAdd);
    docClassList.remove(classToRemove);
  }

  private _setLocalStorageTheme(theme: Theme): void {
    if (theme && this._config.useStorage) {
      this._localStorage?.setItem(VMR_THEME_LOCAL_STORAGE_KEY, theme);
    }
  }

  private _getLocalStorageTheme(): Theme | null {
    const storageTheme = this._localStorage?.getItem(VMR_THEME_LOCAL_STORAGE_KEY);
    return storageTheme as Theme | null;
  }

  private _initThemeWatcher(): void {
    window.matchMedia(PREFERS_COLOR_SCHEME_DARK).addEventListener('change', ({ matches }) => {
      this._toggleThemeClasses(matches ? 'dark' : 'light');
    });
  }
}